import React, {useEffect, useState} from "react";
import {SearchCategoryComponent} from "./SearchCategoryComponent/SearchCategoryComponent";
import {CategoryComponent} from "./CategoryComponent/CategoryComponent";
import {CategoryPickerCategory, CategoryInterfaceInner, CategoryPickerSubcategory} from "../../types/interfaces";
import CategoryService from "../../service/CategoryService";
import {LocaleConvert} from "../../utils/locale";
import {useTranslation} from "react-i18next";

type CategoryPickerComponentProps = {
    serviceCategories: CategoryPickerCategory[]
    onSearchChange : (currentValue : string) => void
    onChangeCategory : (currentCategory : CategoryPickerCategory | null) => void
    onChangeSubcategory : (pikedSubcategory : CategoryPickerSubcategory, currentCategory : CategoryPickerCategory) => void
    locale : string
    isOpen : boolean
}

export const CategoryPickerComponent: React.FC<CategoryPickerComponentProps> = (props) => {

    const [categoryList, setCategoryList] =
        useState<CategoryInterfaceInner[]>(CategoryService.ServiceCategoryInner(props.serviceCategories));

    const { i18n} = useTranslation();

    useEffect(() => {
        setCategoryList(CategoryService.ServiceCategoryInner(props.serviceCategories))
    }, [props.serviceCategories]);

    useEffect(() => {
        const locale = LocaleConvert(props.locale.toLocaleLowerCase());
        i18n.changeLanguage(locale).then((r) => {});
    }, [i18n, props.locale]);
    const onCategoryPick = (currentCategory: CategoryPickerCategory) => {
        setCategoryList((prevState) => {
            return prevState.map((category) => {
                return {
                    ...category,
                    isPicked: category.id === currentCategory.id && currentCategory.isPicked,
                    isOpen : false,
                    isSubcategoryPiked : false,
                    subcategoryList: category.subcategoryList.map((subcategory) => {
                        return {
                            ...subcategory,
                            isPicked: false
                        }
                    })
                };
            });
        });

        props.onChangeCategory(currentCategory)
    };


    const onSubcategoryPick = (currentSubcategory : CategoryPickerSubcategory, currentCategory : CategoryPickerCategory) => {
        setCategoryList((prevState) => {
            return prevState.map((category) => {
                return {
                    ...category,
                    isPicked: false,
                    isOpen : category.id === currentCategory.id && currentSubcategory.isPicked,
                    isSubcategoryPiked : (category.id === currentCategory.id) ? currentSubcategory.isPicked : false,
                    subcategoryList: category.subcategoryList.map((subcategory) => {
                        return {
                            ...subcategory,
                            isPicked: (subcategory.id === currentSubcategory.id && category.id === currentCategory.id) ? currentSubcategory.isPicked : false
                        }
                    })
                };
            });
        });

        props.onChangeSubcategory(currentSubcategory, currentCategory)
    }


    return (
        <>
            {props.isOpen && (<>
                <SearchCategoryComponent categoryList={categoryList} onChange={props.onSearchChange} setCategoryList={setCategoryList}/>
                {categoryList.map(category => (
                    !category.isHidden && <ul className={"list-unstyled mt-3"} key={category.id}>
                        <CategoryComponent category={category} onCategoryPick={onCategoryPick}
                                           onSubcategoryPick={onSubcategoryPick}/>
                    </ul>
                ))}
            </>)}
        </>
    )
}