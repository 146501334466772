import { Button, Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetFilters, setFilters } from '../../../../redux/slices/filters';
import moment from 'moment';
import MePosDateFilter from './mePosDateFilter';
import TypesFilter from './TypesFilter';
import MasterSelectFilter from './MasterSelectFilter';
import HaircutSelectFilter from './HaircutSelectFilter';
import ServiceSelectFilter from './ServiceSelectFilter';
import { useTranslation } from 'react-i18next';

// const EXCLUDED_FIELDS = [
//   'location',
//   'keywords',
//   'gclid',
//   'audience',
//   'unique',
//   'premium',
//   'banner-id',
//   'is-react-qr-generator',
//   'entry-connected-titles',
//   'me-city-connected',
//   'user-email',
//   'user-earned',
//   'unique-one-day',
//   'banner-type',
//   'entry_has_10_scans',
//   'ads_earned',
//   'exact-location'
// ];

const START_DATE_RANGE = {
  selection1: {
    startDate: moment().subtract(1, 'week').toDate(),
    endDate: moment().toDate(),
    key: 'selection1'
  }
};

const TYPE_OF_STATISTIC = 'DAILY_STATISTIC';

const Filters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(START_DATE_RANGE);
  const [statisticType, setStatisticType] = useState(TYPE_OF_STATISTIC);
  const [master, setMaster] = useState('all');
  const [haircutType, setHaircutType] = useState('all');
  const [service, setService] = useState('all');

  const handleApply = () => {
    if (
      !statisticType &&
      master === 'all' &&
      haircutType === 'all' &&
      service === 'all' &&
      JSON.stringify(dateRange) === JSON.stringify(START_DATE_RANGE)
    ) {
      return;
    }
    const filterOptions = {
      statisticType: statisticType,
      master: master,
      haircutType: haircutType,
      service: service,
      periodStart: dateRange.selection1.startDate
        ? moment(dateRange.selection1.startDate).startOf('D').toDate()
        : null,
      periodEnd: dateRange.selection1.endDate
        ? moment(dateRange.selection1.endDate).endOf('D').toDate()
        : null
    };
    dispatch(setFilters(filterOptions));
  };

  const handleReset = () => {
    setMaster('all');
    setHaircutType('all');
    setService('all');
    setDateRange(START_DATE_RANGE);
    setStatisticType(TYPE_OF_STATISTIC);
    dispatch(resetFilters());
  };

  return (
    <div className="d-flex flex-column justify-content-between mb-5">
      <Row className="gx-3 gy-3">
        <Col lg={12} xl={4}>
          <Row className="g-3 h-100">
            <Col sm={6} lg={5}>
              <MePosDateFilter
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Col>
            <Col sm={6} lg={7}>
              <TypesFilter
                selectedType={statisticType}
                setSelectedType={setStatisticType}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={8} xl={5}>
          <Row className="h-100 g-3">
            <Col sm={6} lg={4}>
              <MasterSelectFilter
                startData={dateRange.selection1.startDate}
                endData={dateRange.selection1.endDate}
                selectedMaster={master}
                setSelectedMaster={setMaster}
                haircutType={haircutType}
                service={service}
              />
            </Col>
            <Col sm={6} lg={4}>
              <HaircutSelectFilter
                startData={dateRange.selection1.startDate}
                endData={dateRange.selection1.endDate}
                selectedMaster={master}
                setHairstyles={setHaircutType}
                selectedHairstyle={haircutType}
                setService={setService}
              />
            </Col>
            <Col sm={6} lg={4}>
              <ServiceSelectFilter
                startData={dateRange.selection1.startDate}
                endData={dateRange.selection1.endDate}
                master={master}
                haircutType={haircutType}
                service={service}
                setService={setService}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={4} xl={3}>
          <Row className="h-100 gx-3 ps-lg-2">
            <Col xs={6}>
              <Button
                variant="outline-dark"
                className="btn w-100 rounder-4px px-1 clear-btn"
                onClick={handleReset}
              >
                {t('filters.cancel')}
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="pos-main"
                className="btn w-100 rounder-4px px-1 apply-btn text-white"
                onClick={handleApply}
              >
                {t('filters.apply')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
