import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import ChartCountry from '../../charts/ChartCountry';
import EmptyChartCountry from '../../charts/EmptyChartCountry';
import useUrlParams from '../../../hooks/useUrlParams';

const CountriesItem = ({ modeCompare = false }) => {
  const {
    country,
    cities,
    filters,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    filters: state.filters,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const [labels, setLabels] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const { getCoutryChartData } = useRequestLocation();
  const { isMePage } = useUrlParams();

  const fetchData = async ({ startData, endData }) => {
    const data = await getCoutryChartData({ startData, endData });
    setLabels(data.map((item) => `${item.key} (${item.doc_count})`));
    setCountryData(data.map((item) => item.doc_count));
  };

  useEffect(() => {
    if (!modeCompare) {
      fetchData({ startData: periodStart, endData: periodEnd });
    } else {
      fetchData({ startData: secondPeriodStart, endData: secondPeriodEnd });
    }
    // eslint-disable-next-line
  }, [country, cities, filters, modeCompare, page]);

  const backgroundColor =
    isMePage && modeCompare
      ? ['#CF8500', '#A66A00', '#FFE7BC', '#F8A40F', '#FFC866', '#BDC6CF']
      : isMePage && !modeCompare
      ? ['#276AD4', '#0C3F90', '#001E4C', '#68A2FF', '#BFD8FF', '#8B929A']
      : ['#A803BF', '#700E7F', '#530F5F', '#D455E5', '#E1D5FF', '#8B929A'];

  if (!countryData.length) {
    return <EmptyChartCountry />;
  }

  return (
    <ChartCountry
      labels={labels}
      countryData={countryData}
      backgroundColor={backgroundColor}
    />
  );
};

export default CountriesItem;
