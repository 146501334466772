import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useEffect } from 'react';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../elements/general';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import useUrlParams from '../../../hooks/useUrlParams';
import { useTranslation } from 'react-i18next';
import { requestLimit } from '../../../constants/configs';
import { setPaginationMaxPage } from '../../../redux/slices/meQrData';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

export const getQrData = async ({ baseUrl, page, isMePage }) => {
  try {
    const { data } = await axios(
      `${baseUrl}${
        isMePage ? '/page/user-page-pagination' : '/entry/list'
      }?page=${page}&limit=${requestLimit}`,
      {
        withCredentials: true
      }
    );
    if (data.error) {
      throw new Error(data.error);
    }
    return data;
  } catch (err) {
    return { [isMePage ? 'items' : 'entries']: [], totalPage: 1 };
  }
};

const QrFilter = ({ selectedQrs, setSelectedQrs, setSelectedFolderId }) => {
  const { isFitersActive, filters, page, selectedFolderId } = useSelector(
    (state) => ({
      isFitersActive: state.filters.isFitersActive,
      filters: state.filters,
      page: state.meQrData.pagination.page,
      selectedFolderId: state.meQrData.folderId
    })
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [qrs, setQrs] = useState([]);
  const [isShowFolderTitle, setIsShowFolderTitle] = useState(false);
  const [loading, setLoading] = useState(false);

  const { baseUrl, id, isMePage } = useUrlParams();

  const folders = qrs.reduce((acc, item) => {
    if (item.folderId && !acc.map((el) => el.id).includes(item.folderId)) {
      acc.push({ name: item.folderName, id: item.folderId });
    }
    return acc;
  }, []);

  useEffect(() => {
    setLoading(true);
    getQrData({ baseUrl, page, isMePage }).then((data) => {
      setLoading(false);
      console.log(data);
      setQrs(isMePage ? data.items : data.entries);
      dispatch(setPaginationMaxPage(data.totalPage));
    });
    // eslint-disable-next-line
  }, [page, filters.qrIds]);

  useEffect(() => {
    if (selectedFolderId === null) {
      setIsShowFolderTitle(false);
    }
    // eslint-disable-next-line
  }, [selectedFolderId]);

  const handleQrSelect = (qrId) => {
    setSelectedQrs([qrId]);
    setSelectedFolderId(null);
    setIsShowFolderTitle(false);
  };

  const handleQrSelectFolder = (folderId) => {
    const ids = qrs.reduce((acc, item) => {
      if (item.folderId === folderId) {
        acc.push(item.entryId);
      }
      return acc;
    }, []);
    setSearchValue('');
    setSelectedQrs(ids);
    setSelectedFolderId(folderId);
    setIsShowFolderTitle(true);
  };

  const handleResetQr = () => {
    setSearchValue('');
    setSelectedQrs([]);
    setSelectedFolderId(null);
    setIsShowFolderTitle(false);
    const url = new URL(window.location.href);
    url.searchParams.delete('id');
    window.history.replaceState({}, document.title, url.toString());
  };
  const [filteredQrs, setFilteredQrs] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    const filtered = qrs.filter((item) =>
      item.entryTitle.toLowerCase().includes(value)
    );
    setFilteredQrs(filtered);
  };
  useEffect(() => {
    if (!isFitersActive) {
      setSelectedQrs([]);
    }
    // eslint-disable-next-line
  }, [isFitersActive]);

  useEffect(() => {
    if (id) {
      setSelectedQrs([Number(id)]);
    }
    // eslint-disable-next-line
  }, [id]);

  const filterTitle = isMePage ? t('mePage.pageTitle') : t('filters.qrTitle');
  const allDataTitle = isMePage ? t('mePage.allPages') : t('filters.allCodes');

  return (
    <DropField>
      <FloatingLabel
        label={isShowFolderTitle ? t('filters.foldersTitle') : filterTitle}
      >
        <Dropdown.Toggle className="form-select">
          <div className="me-3 overflow-hidden text-truncate">
            {isShowFolderTitle
              ? qrs.find((item) => item.entryId === selectedQrs[0])?.folderName
              : selectedQrs.length === 1
              ? qrs.find((item) => item.entryId === selectedQrs[0])?.entryTitle
              : allDataTitle}
          </div>
        </Dropdown.Toggle>
      </FloatingLabel>

      <Dropdown.Menu>
        <Dropdown.Item className="px-1">
          <Form.Control
            className="border-0"
            type="text"
            placeholder={`${t('filters.search')}...`}
            onChange={handleSearch}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key === ' ' && searchValue === '') {
                e.preventDefault();
              }
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        {folders.length > 0 && (
          <>
            <Dropdown.Item
              onClick={handleResetQr}
              className={`fw-bold ${
                isMePage ? 'bg-secondary2' : ' bg-secondary'
              }`}
            >
              {t('filters.folders')}
            </Dropdown.Item>
            {folders.map((item) => (
              <Dropdown.Item
                key={item.id}
                onClick={() => handleQrSelectFolder(item.id)}
              >
                {item.name}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item
          onClick={handleResetQr}
          className={`fw-bold ${isMePage ? 'bg-secondary2' : ' bg-secondary'}`}
        >
          {allDataTitle}
        </Dropdown.Item>
        {loading ? (
          <div className="d-flex justify-content-center my-2">
            <Spinner animation="border" role="status" className="m-auto" />
          </div>
        ) : searchValue.trim() ? (
          filteredQrs.length > 0 ? (
            filteredQrs.map((item) => (
              <Dropdown.Item
                key={item.entryId}
                onClick={() => handleQrSelect(item.entryId)}
              >
                {item.entryTitle}
              </Dropdown.Item>
            ))
          ) : (
            <div className="text-center text-muted my-2">
              {t('filters.noMatches')}
            </div>
          )
        ) : (
          qrs.map((item) => (
            <Dropdown.Item
              key={item.entryId}
              onClick={() => handleQrSelect(item.entryId)}
            >
              {item.entryTitle}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </DropField>
  );
};

export default QrFilter;
