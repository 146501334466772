import moment, { isMoment } from 'moment';
import axios from 'axios';
import { HOUR, MINUTE } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import useUrlParams from './useUrlParams';
import { setDataLoad } from '../redux/slices/preloader';
import { requestLimit } from '../constants/configs';
import { setStatisticPath } from '../redux/slices/meQrData';
import { useLocation } from 'react-router-dom';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

axios.interceptors.request.use((config) => {
  const { token } = useUrlParams();
  if (token) {
    config.headers['X-AUTH-TOKEN'] = token;
  }
  config.withCredentials = true;
  return config;
});

const useRequestLocation = () => {
  const {
    location,
    device,
    qrIds,
    country,
    cities,
    periodStart,
    periodEnd,
    page,
    folderId,
    categoryLevel
  } = useSelector((state) => ({
    location: state.filters.location,
    device: state.filters.device,
    qrIds: state.filters.qrIds,
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    page: state.meQrData.pagination.page,
    folderId: state.meQrData.folderId,
    categoryLevel: state.mePosData.categoryLevel
  }));

  const { baseUrl, id, isMePage } = useUrlParams();
  const { pathname } = useLocation();
  const BASE_URL =
    pathname === '/meqr' || pathname === '/mepage'
      ? `${baseUrl}/statistic/request?page=${page}&limit=${requestLimit}`
      : baseUrl;

  const dispatch = useDispatch();

  const parentOrSubCategory =
    categoryLevel === 'parent'
      ? 'service.serviceCategoryNew.parentCategory.name.keyword'
      : 'service.serviceCategoryNew.name.keyword';

  const getParams = () => {
    const query = [];
    const datePeriod = [];
    if (country) {
      query.push({ match: { 'country.keyword': country } });
    }
    if (location.length) {
      query.push({ match: { 'country.keyword': location } });
    }
    if (device.length) {
      query.push({ match: { 'device.keyword': device } });
    }
    if (qrIds.length) {
      query.push({
        terms: {
          [isMePage ? 'page-id' : 'entry-id']: qrIds
        }
      });
    }
    if (periodStart !== null && periodEnd !== null) {
      datePeriod.push({
        range: {
          'created-at-date': {
            gte: periodStart,
            lt: periodEnd
          }
        }
      });
    }
    return { query, datePeriod };
  };

  const getCoutryCityCount = async () => {
    const { query, datePeriod } = getParams();
    try {
      dispatch(setDataLoad({ isGetCoutryCityCountLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [...datePeriod, ...query]
            }
          },
          size: 0,
          aggs: {
            unique_countries: {
              cardinality: {
                field: 'country.keyword'
              }
            },
            unique_cities: {
              cardinality: {
                field: 'city.keyword'
              }
            }
          }
        },
        id
      });
      return {
        countryCount: data.aggregations.unique_countries.value,
        cityCount: data.aggregations.unique_cities.value
      };
    } catch (e) {
      return { countryCount: 0, cityCount: 0 };
    } finally {
      dispatch(setDataLoad({ isGetCoutryCityCountLoad: false }));
    }
  };

  const getCoutryChartData = async ({ startData, endData }) => {
    const { query } = getParams();

    try {
      dispatch(setDataLoad({ isGetCoutryChartDataLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startData,
                      lt: endData
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          track_total_hits: true,
          aggs: {
            country_count: {
              terms: {
                field: `${country.length ? 'city' : 'country'}.keyword`,
                order: {
                  _count: 'desc'
                },
                size: cities.length ? 10000 : 5
              }
            }
          }
        },
        id
      });

      if (cities.length) {
        const aggregations = data.aggregations.country_count.buckets;
        const selectedCities = cities.map((item) =>
          aggregations.find((elem) => elem.key === item)
        );
        const citiesSum = selectedCities.reduce(
          (acc, item) => (acc += item.doc_count),
          0
        );
        return [
          { key: country, doc_count: data.hits.total.value - citiesSum },
          ...selectedCities
        ];
      }
      const other = data.aggregations.country_count.sum_other_doc_count
        ? [
            {
              key: 'Other',
              doc_count: data.aggregations.country_count.sum_other_doc_count
            }
          ]
        : [];

      return [...data.aggregations.country_count.buckets, ...other];
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetCoutryChartDataLoad: false }));
    }
  };

  const getTableData = async ({ startData, endData }) => {
    const { query } = getParams();
    try {
      dispatch(setDataLoad({ isGetTableDataLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startData,
                      lt: endData
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          aggs: {
            country: {
              terms: {
                field: 'country.keyword',
                size: 10000
              },
              aggs: {
                unique_doc_count: {
                  filter: {
                    term: {
                      unique: true
                    }
                  }
                },
                top_city: {
                  terms: {
                    field: 'city.keyword',
                    size: 10000
                  },
                  aggs: {
                    unique_doc_count: {
                      filter: {
                        term: {
                          unique: true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        id
      });
      return {
        dataWithCity: data.aggregations.country.buckets
      };
    } catch (e) {
      return { dataWithCity: [] };
    } finally {
      dispatch(setDataLoad({ isGetTableDataLoad: false }));
    }
  };

  const getMapData = async () => {
    const { query, datePeriod } = getParams();

    if (cities.length) {
      cities.forEach((item) => {
        query.push({ match: { 'city.keyword': item } });
      });
    }
    try {
      dispatch(setDataLoad({ isGetMapDataLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [...datePeriod, ...query]
            }
          },
          size: 0,
          track_total_hits: true,
          aggs: {
            cities: {
              terms: {
                field: 'city.keyword',
                size: 10000
              },
              aggs: {
                latitude: {
                  avg: {
                    field: 'latitude'
                  }
                },
                longitude: {
                  avg: {
                    field: 'longitude'
                  }
                }
              }
            }
          }
        },
        id
      });
      return {
        data: data.aggregations.cities.buckets,
        total: data.hits.total.value
      };
    } catch (e) {
      return { data: [], total: 0 };
    } finally {
      dispatch(setDataLoad({ isGetMapDataLoad: false }));
    }
  };

  const getTotalScans = async (uniq = false) => {
    const query = [];
    if (uniq) {
      query.push({
        term: {
          unique: true
        }
      });
    }
    if (qrIds.length) {
      query.push({
        terms: {
          [isMePage ? 'page-id' : 'entry-id']: qrIds
        }
      });
    }
    try {
      dispatch(setDataLoad({ isGetTotalScansLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [...query]
            }
          },
          size: 0,
          track_total_hits: true
        },
        id
      });
      return data.hits.total.value;
    } catch (e) {
      return 0;
    } finally {
      dispatch(setDataLoad({ isGetTotalScansLoad: false }));
    }
  };

  const getFilteredTotalScans = async (startDate, endDate, uniq = false) => {
    const query = [];
    if (location.length) {
      query.push({ match: { 'country.keyword': location } });
    }
    if (device.length) {
      query.push({ match: { 'device.keyword': device } });
    }
    if (qrIds.length) {
      query.push({
        terms: {
          [isMePage ? 'page-id' : 'entry-id']: qrIds
        }
      });
    }
    if (uniq) {
      query.push({
        term: {
          unique: true
        }
      });
    }
    try {
      dispatch(setDataLoad({ isGetFilteredTotalScansLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startDate,
                      lt: endDate
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          track_total_hits: true
        },
        id
      });
      return { filteredScans: data.hits.total.value };
    } catch (e) {
      return {
        filteredScans: 0
      };
    } finally {
      dispatch(setDataLoad({ isGetFilteredTotalScansLoad: false }));
    }
  };

  // ready
  const getFilteredTotalUsers = async ({
    startDate,
    endDate,
    period,
    uniq = false
  }) => {
    const query = [];
    const datePeriod = [];
    if (location.length) {
      query.push({ match: { 'country.keyword': location } });
    }
    if (device.length) {
      query.push({ match: { 'device.keyword': device } });
    }
    if (qrIds.length) {
      query.push({
        terms: {
          [isMePage ? 'page-id' : 'entry-id']: qrIds
        }
      });
    }
    if (uniq) {
      query.push({
        term: {
          unique: true
        }
      });
    }
    if (startDate !== null && endDate !== null) {
      datePeriod.push({
        range: {
          'created-at-date': {
            gte: startDate,
            lt: endDate
          }
        }
      });
    }
    try {
      dispatch(setDataLoad({ isGetFilteredTotalUsersLoad: true }));
      const {
        data: { documents: data, reportPath }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [...datePeriod, ...query]
            }
          },
          sort: [
            {
              'created-at-date': {
                order: 'asc'
              }
            }
          ],
          size: 0,
          track_total_hits: true,
          aggs: {
            created_at_period: {
              date_histogram: {
                field: 'created-at-date',
                interval: period,
                min_doc_count: 0
              }
            }
          }
        },
        id
      });
      const dates = [];
      for (
        let date = moment(
          startDate ||
            data.aggregations.created_at_period.buckets[0].key_as_string
        ).startOf(period);
        date.isBefore(moment(endDate).endOf(period));
        date.add(1, period)
      ) {
        dates.push({
          key_as_string: date.toISOString(),
          doc_count:
            data.aggregations.created_at_period.buckets.find((item) =>
              moment
                .utc(item.key_as_string)
                .isBetween(
                  moment(date).subtract(1, 's'),
                  moment(date).add(1, period)
                )
            )?.doc_count || 0
        });
      }
      dispatch(setStatisticPath(reportPath));
      return dates;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetFilteredTotalUsersLoad: false }));
    }
  };

  const getScansDay = async ({ startDate, endDate, period }) => {
    const query = [];
    if (location.length) {
      query.push({ match: { 'country.keyword': location } });
    }
    if (device.length) {
      query.push({ match: { 'device.keyword': device } });
    }
    if (qrIds.length) {
      query.push({
        terms: {
          [isMePage ? 'page-id' : 'entry-id']: qrIds
        }
      });
    }

    try {
      dispatch(setDataLoad({ isGetScansDayLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startDate,
                      lt: endDate
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          // terminate_after: 50000,
          aggs: {
            count: {
              date_histogram: {
                field: 'created-at-date',
                interval: period === HOUR ? HOUR : '1m',
                min_doc_count: period === HOUR ? 0 : 1
              },
              aggs: {
                unique_doc_count: {
                  filter: {
                    term: {
                      unique: true
                    }
                  }
                }
              }
            }
          }
        },
        id
      });
      const totalScans = data.aggregations.count.buckets;
      let scans = [];
      let unique = [];
      if (period === HOUR) {
        scans = new Array(24).fill(0);
        unique = new Array(24).fill(0);
        totalScans.forEach((bucket) => {
          const hour = moment(bucket.key_as_string).hours();
          scans[hour] += bucket.doc_count;
          unique[hour] += bucket.unique_doc_count.doc_count;
        });
      } else if (period === MINUTE) {
        scans = new Array(1440).fill(0);
        unique = new Array(1440).fill(0);
        totalScans.forEach((bucket) => {
          const date = moment.utc(bucket.key_as_string);
          const minutes = date.diff(date.clone().startOf('day'), 'minutes');
          scans[minutes] += bucket.doc_count;
          unique[minutes] += bucket.unique_doc_count.doc_count;
        });
      }
      return {
        scans,
        unique
      };
    } catch (e) {
      return {
        scans: [],
        unique: []
      };
    } finally {
      dispatch(setDataLoad({ isGetScansDayLoad: false }));
    }
  };

  const getCountries = async () => {
    try {
      dispatch(setDataLoad({ isGetCountriesLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          size: 0,
          aggs: {
            countries: {
              terms: {
                field: 'country.keyword',
                order: {
                  _key: 'asc'
                },
                size: 300
              }
            }
          }
        },
        id
      });
      return data.aggregations.countries.buckets;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetCountriesLoad: false }));
    }
  };

  const getDevices = async ({ startData, endData }) => {
    const { query } = getParams();
    try {
      dispatch(setDataLoad({ isGetDevicesLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startData,
                      lt: endData
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          aggs: {
            devices: {
              terms: {
                field: 'device.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10000
              }
            }
          }
        },
        id
      });
      return data.aggregations.devices.buckets;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetDevicesLoad: false }));
    }
  };

  const getOs = async ({ startData, endData }) => {
    const { query } = getParams();
    try {
      dispatch(setDataLoad({ isGetOsLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    'created-at-date': {
                      gte: startData,
                      lt: endData
                    }
                  }
                },
                ...query
              ]
            }
          },
          size: 0,
          aggs: {
            os: {
              terms: {
                field: 'os.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10000
              }
            }
          }
        },
        id
      });
      return data.aggregations.os.buckets;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetOsLoad: false }));
    }
  };

  const getAllCountries = async () => {
    const { query, datePeriod } = getParams();
    try {
      dispatch(setDataLoad({ isGetAllCountriesLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [...datePeriod, ...query]
            }
          },
          size: 0,
          aggs: {
            country_count: {
              terms: {
                field: 'country.keyword',
                order: { _key: 'asc' },
                size: 200
              }
            }
          }
        },
        id
      });
      return data.aggregations.country_count.buckets;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetAllCountriesLoad: false }));
    }
  };

  const getCitiesByCountry = async (countryName) => {
    const { query, datePeriod } = getParams();
    try {
      dispatch(setDataLoad({ isGetCitiesByCountryLoad: true }));
      const {
        data: { documents: data }
      } = await axios.post(BASE_URL, {
        searchParams: {
          query: {
            bool: {
              filter: [
                ...datePeriod,
                { match: { 'country.keyword': countryName } },
                ...query
              ]
            }
          },
          size: 0,
          aggs: {
            country_count: {
              terms: {
                field: 'city.keyword',
                order: { _key: 'asc' },
                size: 500
              }
            }
          }
        },
        id
      });
      return data.aggregations.country_count.buckets;
    } catch (e) {
      return [];
    } finally {
      dispatch(setDataLoad({ isGetCitiesByCountryLoad: false }));
    }
  };

  const getCsvData = async (generateNew) => {
    const { query, datePeriod } = getParams();
    const id = qrIds.length === 1 ? qrIds[0] : null;
    const request = {
      searchParams: {
        query: {
          bool: {
            filter: [...datePeriod, ...query]
          }
        },
        size: 10000
      },
      id
    };
    try {
      const { data } = await axios.post(
        `${baseUrl}/statistic/report-create?create_report=${generateNew}${
          folderId ? `$folder=${folderId}` : ''
        }`,
        request
      );
      return data;
    } catch (e) {
      return [];
    }
  };

  const getRevenueTrade = async (
    endData,
    startData,
    master,
    haircutType,
    service
  ) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({ term: { [parentOrSubCategory]: haircutType } });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    query.push({ term: { 'status.id': 6 } });
    query.push({
      term: { 'status.id': 7 }
    });
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            filter: {
              terms: {
                'status.id': [6, 7]
              }
            }
          }
        },
        aggs: {
          group_by_day: {
            date_histogram: {
              field: 'date',
              calendar_interval: 'day',
              order: {
                _key: 'asc'
              }
            },
            aggs: {
              price: {
                terms: {
                  field: 'price'
                }
              },
              aggs: {
                terms: {
                  field: 'status.id'
                }
              }
            }
          }
        }
      });
      return data.aggregations.group_by_day.buckets;
    } catch (e) {
      return [];
    }
  };

  const getServicePop = async (
    endData,
    startData,
    master,
    haircutType,
    service,
    complete = false
  ) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({ term: { [parentOrSubCategory]: haircutType } });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    if (complete) {
      query.push({ term: { 'status.id': 6 } });
      query.push({
        term: { 'status.id': 7 }
      });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            should: [...query]
          }
        },
        aggs: {
          count_records: {
            terms: {
              field: 'service.name.keyword'
            },
            aggs: {
              serviceDeleted: {
                terms: {
                  field: 'service.deleted'
                }
              },
              price: {
                terms: { field: 'price' },
                aggs: {
                  status: { terms: { field: 'status.id' } }
                }
              }
            }
          },
          count_masters: {
            terms: {
              field: 'employee.id'
            },
            aggs: {
              surname: { terms: { field: 'employee.surname.keyword' } },
              name: { terms: { field: 'employee.name.keyword' } },
              position: {
                terms: { field: 'employee.employeePosition.name.keyword' }
              },
              price: {
                terms: { field: 'price' },
                aggs: {
                  status: { terms: { field: 'status.id' } }
                }
              }
            }
          },
          status_service: {
            terms: {
              field: 'service.name.keyword'
            },
            aggs: {
              status: {
                terms: {
                  field: 'status.id'
                }
              },
              serviceDeleted: {
                terms: {
                  field: 'service.deleted'
                }
              }
            }
          },
          status_master: {
            terms: {
              field: 'employee.id'
            },
            aggs: {
              surname: { terms: { field: 'employee.surname.keyword' } },
              name: { terms: { field: 'employee.name.keyword' } },
              position: {
                terms: { field: 'employee.employeePosition.name.keyword' }
              },
              status: { terms: { field: 'status.id' } }
            }
          }
        }
      });
      return data.aggregations;
    } catch (e) {
      return [];
    }
  };

  const getRecords = async (
    endData,
    startData,
    master,
    haircutType,
    service
  ) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({ term: { [parentOrSubCategory]: haircutType } });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            must: [...query]
          }
        },
        aggs: {
          group_by_day: {
            date_histogram: {
              field: 'date',
              calendar_interval: 'day',
              order: {
                _key: 'asc'
              }
            },
            aggs: {
              group_by_status: {
                terms: {
                  field: 'status.id'
                }
              }
            }
          }
        }
      });
      return data.aggregations.group_by_day.buckets;
    } catch (e) {
      return [];
    }
  };

  const getRecordsSum = async (
    endData,
    startData,
    master,
    haircutType,
    service
  ) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({ term: { [parentOrSubCategory]: haircutType } });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            must: [...query],
            filter: {
              terms: {
                'status.id': [4, 5, 6, 7]
              }
            }
          }
        },
        aggs: {
          group_by_status_id: {
            terms: {
              field: 'status.id'
            },
            aggs: {
              price: {
                terms: {
                  field: 'price'
                }
              }
            }
          }
        }
      });
      return data.aggregations.group_by_status_id.buckets;
    } catch (e) {
      return [];
    }
  };

  const getFilial = async (startData, endData) => {
    const statisticIndex =
      baseUrl.indexOf('/statistic/') + '/statistic/'.length;
    const id = baseUrl.slice(statisticIndex);
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 1,
        query: {
          bool: {
            must: [
              {
                term: {
                  'branch.id': id
                }
              }
            ]
          }
        }
      });
      return data.branchInfo;
    } catch (e) {
      return [];
    }
  };

  const getMasters = async (endData, startData, haircutType, service) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({
        term: {
          [parentOrSubCategory]: haircutType
        }
      });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,

        size: 0,
        query: {
          bool: {
            must: [...query]
          }
        },
        aggs: {
          employee_surnames: {
            terms: {
              field: 'employee.surname.keyword'
            },
            aggs: {
              name: {
                terms: {
                  field: 'employee.name.keyword'
                }
              }
            }
          }
        }
      });
      return data.aggregations.employee_surnames.buckets;
    } catch (e) {
      return [];
    }
  };

  const getHairstyles = async (endData, startData, masters) => {
    const query = [];
    if (masters !== 'all') {
      query.push({ term: { 'employee.surname.keyword': masters.surname } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            must: [...query]
          }
        },
        aggs: {
          categories: {
            terms: {
              field: 'service.serviceCategoryNew.parentCategory.name.keyword'
            },
            aggs: {
              parentCategoryId: {
                terms: {
                  field: 'service.serviceCategoryNew.parentCategory.id'
                }
              },
              parentCategoryDeleted: {
                terms: {
                  field: 'service.serviceCategoryNew.parentCategory.deleted'
                }
              },
              subcategories: {
                terms: { field: 'service.serviceCategoryNew.name.keyword' },
                aggs: {
                  subcategoryId: {
                    terms: {
                      field: 'service.serviceCategoryNew.id'
                    }
                  },
                  subcategoryDeleted: {
                    terms: {
                      field: 'service.serviceCategoryNew.deleted'
                    }
                  }
                }
              }
            }
          }
        }
      });
      return data.aggregations.categories.buckets;
    } catch (e) {
      return [];
    }
  };

  const getServices = async (endData, startData, haircutType, master) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({
        term: {
          [parentOrSubCategory]: haircutType
        }
      });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,

        size: 0,
        query: {
          bool: {
            must: [...query]
          }
        },
        aggs: {
          services: {
            terms: {
              field: 'service.name.keyword'
            },
            aggs: {
              serviceDeleted: {
                terms: {
                  field: 'service.deleted'
                }
              }
            }
          }
        }
      });
      return data.aggregations.services.buckets;
    } catch (e) {
      return [];
    }
  };

  const getPaginationStat = async (
    endData,
    startData,
    master,
    haircutType,
    service
  ) => {
    const query = [];
    if (haircutType !== 'all') {
      query.push({
        term: { [parentOrSubCategory]: haircutType }
      });
    }
    if (service !== 'all') {
      query.push({ term: { 'service.name.keyword': service } });
    }
    if (master !== 'all') {
      query.push({ term: { 'employee.surname.keyword': master.surname } });
    }
    try {
      const { data } = await axios.post(BASE_URL, {
        start_date: startData,
        end_date: endData,
        size: 0,
        query: {
          bool: {
            must: [...query]
          }
        },
        aggs: {
          group_by_day: {
            date_histogram: {
              field: 'date',
              calendar_interval: 'day',
              order: {
                _key: 'asc'
              },
              format: 'dd.MM.yy'
            },
            aggs: {
              group_by_status: {
                terms: {
                  field: 'status.id'
                },
                aggs: {
                  price: { terms: { field: 'price' } }
                }
              }
            }
          },
          service: {
            terms: { field: 'service.name.keyword' },
            aggs: {
              serviceDeleted: {
                terms: {
                  field: 'service.deleted'
                }
              },
              price: {
                terms: { field: 'price' },
                aggs: {
                  status: {
                    terms: { field: 'status.id' }
                  }
                }
              },
              subcategory: {
                terms: { field: 'service.serviceCategoryNew.name.keyword' },
                aggs: {
                  subcategoryDeleted: {
                    terms: {
                      field: 'service.serviceCategoryNew.deleted'
                    }
                  }
                }
              },
              status: { terms: { field: 'status.id' } },
              category: {
                terms: {
                  field:
                    'service.serviceCategoryNew.parentCategory.name.keyword'
                },
                aggs: {
                  parentCategoryDeleted: {
                    terms: {
                      field: 'service.serviceCategoryNew.parentCategory.deleted'
                    }
                  }
                }
              }
            }
          },
          masters: {
            terms: { field: 'employee.id' },
            aggs: {
              surname: { terms: { field: 'employee.surname.keyword' } },
              name: { terms: { field: 'employee.name.keyword' } },
              position: {
                terms: { field: 'employee.employeePosition.name.keyword' }
              },
              status: { terms: { field: 'status.id' } },
              price: {
                terms: { field: 'price' },
                aggs: {
                  status: {
                    terms: { field: 'status.id' }
                  }
                }
              }
            }
          }
        }
      });
      return data.aggregations;
    } catch (e) {
      return [];
    }
  };

  const getEventsFilters = async (
    startDate = null,
    endDate = null,
    eventIds = []
  ) => {
    const start = startDate
      ? isMoment(startDate)
        ? startDate.clone().startOf('D').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        : moment(startDate)
            .clone()
            .endOf('D')
            .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
      : startDate;
    const end = endDate
      ? isMoment(endDate)
        ? endDate.clone().endOf('D').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        : moment(endDate).clone().endOf('D').format('YYYY-MM-DDTHH:mm:ss.SSS') +
          'Z'
      : endDate;
    try {
      const { data } = await axios.post(
        baseUrl + '/for-organizers/statistic/ajax/get/info',
        {
          startDate: start,
          endDate: end,
          eventIds: eventIds
        },
        {}
      );
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getCoutryCityCount,
    getCoutryChartData,
    getTableData,
    getMapData,
    getTotalScans,
    getScansDay,
    getCountries,
    getDevices,
    getFilteredTotalScans,
    getFilteredTotalUsers,
    getOs,
    getAllCountries,
    getCitiesByCountry,
    getCsvData,
    getRecords,
    getMasters,
    getHairstyles,
    getServices,
    getFilial,
    getRecordsSum,
    getRevenueTrade,
    getServicePop,
    getPaginationStat,
    getEventsFilters
  };
};
export default useRequestLocation;
