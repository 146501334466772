import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Device from '../device';
import OS from '../OS';
import useUrlParams from '../../../hooks/useUrlParams';

const Devices = () => {
  const { isCompareMode } = useSelector((state) => ({
    isCompareMode: state.filters.isCompareMode
  }));
  const { t } = useTranslation();
  const { isMePage } = useUrlParams();

  const devicesDescriptionText = isMePage
    ? t('mePage.devicesDescription')
    : t('devices.description');

  return (
    <Col className="pb-5 pb-lg-0" xs={12}>
      <div className="px-4 pt-4 pb-5 bg-white h-100">
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <div className="fw-bold fs-4 mb-2 text-capitalize">
              {t('devices.devices')}
            </div>
          </Col>
          <Col xs="auto">
            <div className="fs-7">
              <Form.Check
                checked={isCompareMode === true}
                type="radio"
                label={t('devices.compare')}
                className={isMePage ? 'checkbox-me-page' : ''}
                readOnly
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="text-gray">{devicesDescriptionText}</div>
          </Col>
        </Row>
        <Row className="mt-4 gy-3">
          <Device />
          <OS />
        </Row>
      </div>
    </Col>
  );
};

export default Devices;
