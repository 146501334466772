import React, {useEffect, useState} from "react";

import {SubcategoryComponent} from "./SubcategoryComponent/SubcategoryComponent";
import styles from "./CategoryComponent.module.css"
import {
    CategoryPickerCategory,
    CategoryInterfaceInner,
    CategoryPickerSubcategory,
    CategoryPickerSubcategoryInner
} from "../../../types/interfaces";

type CategoryComponentProps = {
    category: CategoryInterfaceInner
    onCategoryPick : (currentCategory : CategoryPickerCategory) => void
    onSubcategoryPick : (currentSubcategory : CategoryPickerSubcategory, currentCategory: CategoryPickerCategory) => void
}

export const CategoryComponent: React.FC<CategoryComponentProps> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.category.isOpen);
    const [pickedCategory, setPickedCategory] = useState<boolean>(props.category.isPicked);
    const [pickedSubcategory, setPickedSubcategory] = useState<boolean>(props.category.isSubcategoryPiked);

    useEffect(() => {
        setPickedCategory(props.category.isPicked);
        setPickedSubcategory(props.category.isSubcategoryPiked);
        setIsOpen(props.category.isOpen);
    }, [props.category.isPicked, props.category.isSubcategoryPiked, props.category.isOpen]);

    const onSubcategoryPick = (currentSubcategory : CategoryPickerSubcategory) => {
        currentSubcategory.isPicked = !currentSubcategory.isPicked
        props.onSubcategoryPick(currentSubcategory, props.category);
    }

    const onOpenCategory = (event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    }

    const onPickCategory = (event : React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setPickedCategory(!pickedCategory);
        props.category.isPicked = !props.category.isPicked
        props.onCategoryPick(props.category)
    }

    return (
        <>
            <li className={`cursor-pointer`}>
                <span onClick={onPickCategory}
                    className={`${styles.customListStyle} ${(isOpen || pickedSubcategory) && !pickedCategory ? 'bg-light' : pickedCategory ? styles.pickedBg : ''} 
                    text-dark w-100 d-block rounded position-relative`}>
                    {props.category.subcategoryList.length !== 0 ?
                        <i className={`${isOpen ? styles.rotatedIcon : ''} bi bi-chevron-right pe-2`}
                           onClick={onOpenCategory}></i>
                        : <i className={`${styles.disableStyleChevron} bi bi-chevron-right pe-2`}></i>
                    }
                    <span className={`${pickedCategory ? 'text-primary' : ''}`}>{props.category.name}</span>
                    {pickedCategory && <i className="bi bi-check-lg text-primary position-absolute end-0 translate-middle-x"></i>}
                </span>
                {props.category.subcategoryList.map((subcategory : CategoryPickerSubcategoryInner) => (
                    !subcategory.isHidden && <SubcategoryComponent
                        key={subcategory.id}
                        isOpen={isOpen}
                        onSubcategoryPick={onSubcategoryPick}
                        subcategory={subcategory}
                    />
                ))}
            </li>
        </>
    )
}