import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

export const ColorLabel = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color || '#9b27af'};
  border: ${(props) => props.borderColor ? `1px solid ${props.borderColor}`: 'none'};
  border-radius: ${(props) => props.borderRadius ?? '0'};
  display: inline-block;
  margin: 8px;
`;

export const DropField = styled(Dropdown)`
  /* height: 100%; */
  .btn.show {
    color: #3e4957;
    background-color: white;
  }
  .dropdown-menu.show {
    width: 100%;
    max-height: 300px;
    overflow: scroll;
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3e4957;
    background-color: white;
    border: none;
    &::after {
      display: none;
    }

    &:hover {
      color: #3e4957;
      background-color: white;
    }
  }
`;
