import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currency: '',
  isStatisticData: false,
  categoryLevel: '',
  isArchivedSelectedCategory: false
};

const mePosData = createSlice({
  name: 'mePosData',
  initialState,
  reducers: {
    setCurrency(state, { payload }) {
      state.currency = payload;
    },
    setIsStatisticData(state, { payload }) {
      state.isStatisticData = payload;
    },
    setCategoryLevel(state, { payload }) {
      state.categoryLevel = payload;
    },
    setIsArchivedSelectedCategoryl(state, { payload }) {
      state.isArchivedSelectedCategory = payload;
    }
  }
});
export const {
  setCurrency,
  setIsStatisticData,
  setCategoryLevel,
  setIsArchivedSelectedCategoryl
} = mePosData.actions;
export default mePosData.reducer;
