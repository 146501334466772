import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const MePageTitle = () => {
  const { t } = useTranslation();
  return (
    <Col xs={12} className="pt-0 pt-md-5 mt-lg-5">
      <h1 className="fs-1 mt-5 d-none d-md-block">{t('header.title')}</h1>
      <h1 className="fs-2 mt-5 d-md-none">{t('header.title')}</h1>
      <div className="text-gray mb-lg-3"> {t('mePage.headerDescription')}</div>
    </Col>
  );
};

export default MePageTitle;
