import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../../elements/general';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../../hooks/useRequestLocation';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';



const ServiceSelectFilter = ({ endData, startData, setService, service, haircutType, master}) => {
  const [serviceList, setServiceList] = useState([])
  const { t } = useTranslation();
  const { getServices } = useRequestLocation();

  useEffect(() => {
    const periodToStart =
      startData !== null
        ? moment(startData).format('YYYY-MM-DD')
        : moment().subtract(1, 'week').format('YYYY-MM-DD');
    const periodToEnd =
      endData !== null
        ? moment(endData).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
    getServices(periodToEnd, periodToStart, haircutType, master).then(
      (countHairstyles) => {
        setServiceList(
          countHairstyles.map((el) => {
            return {
              name: `${el.key}${
                !!el.serviceDeleted.buckets[0].key
                  ? ` ${t('filters.archive')}`
                  : ''
              }`,
              nameOriginal: el.key
            };
          })
        );
      }
    );
  }, [startData, endData, haircutType]);

  const handleSelect = (key) => {
    setService(key);
  };

  return (
    <DropField className="custom-dropdown py-2 rounder-4px border">
      <Dropdown.Toggle
        className={`form-select ${haircutType === 'all' ? 'disabled' : ''}`}
        aria-expanded={'false'}
      >
        {serviceList.find((item) => item.nameOriginal === service)
          ? serviceList.find((item) => item.nameOriginal === service).name
          : t('filters.allServices')}
      </Dropdown.Toggle>
      {/*{haircutType !== 'all' &&*/}
      <Dropdown.Menu className="custom-dropdown-menu">
        <Dropdown.Item
          onClick={() => handleSelect('all')}
          className={
            haircutType === 'all' ||
            !serviceList.some((item) => item.nameOriginal === service)
              ? 'active'
              : ''
          }
        >
          {t('filters.allServices')}
        </Dropdown.Item>
        {serviceList.map((item) => (
          <Dropdown.Item
            key={item.nameOriginal}
            onClick={() => handleSelect(item.nameOriginal)}
            className={item.nameOriginal === service ? 'active' : ''}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
      {/*}*/}
    </DropField>
  );
};

export default ServiceSelectFilter;
