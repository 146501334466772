export const LocaleConvert = (locale: string): string => {
  const languageMap: { [key: string]: string } = {
    'ua': 'uk',
    'en': 'en',
    'uk' : 'uk',
  };

  return languageMap[locale] || 'en';
};


