import { useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Devices from '../../components/blocks/devices';
import Filters from '../../components/blocks/filters';
import ScansDaytime from '../../components/blocks/scansDaytime';
import ScansLocation from '../../components/blocks/scansLocation';
import TotalScans from '../../components/blocks/totalScans';
import TotalUsersChart from '../../components/blocks/totalUsersChart';
import UniqueUsers from '../../components/blocks/uniqueUsers';
import UniqueUsersChart from '../../components/blocks/uniqueUsersChart';
import { setPreloader } from '../../redux/slices/preloader';
import DownloadCsv from '../../components/blocks/downloadCsv';
import useUrlParams from '../../hooks/useUrlParams';
import { setFilters } from '../../redux/slices/filters';
import MePageTitle from '../../components/blocks/title/mePageTitle';

const MePagePage = () => {
  const { dataLoad, isStatisticCollecting, statisticPath, filters } =
    useSelector((state) => ({
      dataLoad: state.preloader.dataLoad,
      isStatisticCollecting: state.meQrData.isStatisticCollecting,
      statisticPath: state.meQrData.statisticPath,
      filters: state.filters
    }));

  const dispatch = useDispatch();
  const { id } = useUrlParams();

  useEffect(() => {
    dispatch(setPreloader(Object.values(dataLoad).some((item) => item)));
  }, [dispatch, dataLoad]);

  useEffect(() => {
    if (id) {
      dispatch(
        setFilters({
          ...filters,
          qrIds: [Number(id)],
          mode: 'period',
          isFitersActive: true
        })
      );
    }
    // eslint-disable-next-line
  }, [id]);

  if (id && filters.qrIds.length === 0) {
    return null;
  }

  return (
    <>
      {(statisticPath || isStatisticCollecting) && <DownloadCsv />}
      <Container>
        <Row className="g-4 mb-5">
          <MePageTitle />
          <Filters />
          <TotalScans />
          <UniqueUsers />
          <TotalUsersChart />
          <UniqueUsersChart />
          <ScansDaytime />
          <ScansLocation />
          <Devices />
        </Row>
      </Container>
    </>
  );
};

export default MePagePage;
