import { useTranslation } from 'react-i18next';

export default function HideFiltersButton({showFilters, setShowFilters}) {
  const {t} = useTranslation()
  return (
    <button
      className={`btn fw-bold w-100 filters-button btn-outline-primary ${
        showFilters ? 'active' : ''
      }`}
      style={{ padding: '12px 4px' }}
      onClick={() => setShowFilters(!showFilters)}
    >
      <span>
        <i className="bi bi-funnel me-1"></i> {t('filters.filters')}
      </span>
    </button>
  );
};