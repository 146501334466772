import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from './loader';
import DoneIcon from './svg/DoneIcon';
import { useSelector } from 'react-redux';
import useUrlParams from '../../../hooks/useUrlParams';

const DownloadCsv = () => {
  const { statisticPath } = useSelector((state) => ({
    statisticPath: state.meQrData.statisticPath
  }));
  const { t } = useTranslation();
  const { isMePage } = useUrlParams();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = statisticPath;
    link.download = 'statistics.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section
      className="position-sticky top-0 bg-white w-100 shadow-sm"
      style={{ zIndex: 450 }}
    >
      <Container className="me-qr">
        <div className="d-flex align-items-center gap-2 py-2 fs-7">
          {statisticPath ? <DoneIcon /> : <Loader color="#3A83F6" />}
          <div className="me-3">
            {statisticPath
              ? t('filters.messageDone')
              : t('filters.messageProcess')}
          </div>
          <Button
            onClick={handleDownload}
            disabled={statisticPath.length === 0}
            variant={isMePage ? 'primary2' : 'primary'}
            className={`px-3 fw-bold${isMePage ? ' text-white' : ''}`}
          >
            {t('filters.downloadFile')}
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default DownloadCsv;
