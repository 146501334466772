import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import useUrlParams from '../../hooks/useUrlParams';
// import { PieWrapper } from './ChartCountry';

ChartJS.register(ArcElement, Tooltip, Legend);

const colorSets = {
  device: ['#A803BF', '#700E7F', '#530F5F', '#D455E5', '#E1D5FF', '#8B929A'],
  os: ['#9765CC', '#6E34AA', '#989EFF', '#FFCC58', '#CCA265', '#8B929A']
};

const colorSetsMePage = {
  device: ['#276AD4', '#0C3F90', '#001E4C', '#68A2FF', '#BFD8FF', '#8B929A'],
  os: ['#CF8500', '#A66A00', '#FFE7BC', '#F8A40F', '#FFC866', '#BDC6CF']
};

const DeviceChart = ({ data, color, title }) => {
  const { isMePage } = useUrlParams();
  const options = {
    // responsive: false,
    maintainAspectRatio: false,
    responsive: true,
    layout: {},

    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'medium',
        labels: {
          fontColor: '#3E4957',
          fontSize: 16,
          boxWidth: 13,
          boxHeight: 13,
          font: {
            weight: '400',
            size: 16
          }
        },
        title: {
          display: true,
          text: title,
          // padding: {
          //   top: 100
          // },
          font: {
            size: 18,
            weight: '400'
          }
        }
      }
    }
  };

  const labels = data.map((item) =>
    item[0].length < 10 ? item[0] + '             ' : item[0]
  );
  const counts = data.map((item) => item[1]);
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Scans',
        data: counts,
        backgroundColor: isMePage
          ? colorSetsMePage[color] || colorSets.device
          : colorSets[color] || colorSets.device,
        borderColor: '#FFFFFF',
        borderWidth: 1
      }
    ]
  };
  return (
    <PieWrapper className="d-flex justify-content-center">
      <Pie data={chartData} options={options} />
    </PieWrapper>
  );
};

export default DeviceChart;

const PieWrapper = styled.div`
  canvas {
    height: 200px !important;
  }
  @media (min-width: 992px) {
    canvas {
      height: 300px !important;
    }
  }
`;
