import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import moment from 'moment';
import LoaderMePos from '../../loader/LoaderMePos';

const typesOfStatistic = [
  { key: 'DAILY_STATISTIC', labelKey: 'statistic.daily' },
  { key: 'POPULAR_SERVICE', labelKey: 'filters.popularityServices' },
  { key: 'POPULAR_MASTER', labelKey: 'filters.popularityMasters' },
  { key: 'COMPLETE_MASTER', labelKey: 'filters.completeMasters' },
  { key: 'COMPLETE_SERVICE', labelKey: 'filters.completeServices' }
];

const ExportCsvMePos = () => {
  const {
    statisticType,
    startData,
    endData,
    service,
    master,
    haircutType,
    currency
  } = useSelector((state) => ({
    statisticType: state.filters.statisticType,
    startData: state.filters.periodStart,
    endData: state.filters.periodEnd,
    service: state.filters.service,
    master: state.filters.master,
    haircutType: state.filters.haircutType,
    currency: state.mePosData.currency
  }));

  const { t } = useTranslation();
  const ref = useRef(null);
  const { getPaginationStat } = useRequestLocation();

  const [csvData, setCsvData] = useState([]);
  const [mainLabels, setMainLabels] = useState([]);
  const [loading, setLoading] = useState(false);

  const periodToStart =
    startData !== null
      ? moment(startData).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD');
  const periodToEnd =
    endData !== null
      ? moment(endData).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

  const fileName = `${t(
    typesOfStatistic.find((item) => item.key === statisticType).labelKey
  )} (${moment(periodToStart).format('DD.MM.YYYY')} - ${moment(
    periodToEnd
  ).format('DD.MM.YYYY')}).csv`;

  const getDailyStatistic = (statisticData) => {
    setMainLabels([
      t('statistic.date'),
      t('statistic.totalRecords'),
      t('statistic.bookingCompleted'),
      t('statistic.bookingCancel'),
      t('statistic.clientNotCome'),
      t('statistic.income'),
      t('statistic.maxPrice'),
      t('statistic.minPrice'),
      t('statistic.averagePrice')
    ]);
    setCsvData(
      statisticData.group_by_day.buckets.map((el) => {
        const completedData = el.group_by_status.buckets.find(
          (comp) => comp.key === 6
        );
        const completedDataPart = el.group_by_status.buckets.find(
          (comp) => comp.key === 7
        );
        const hasntArrived = el.group_by_status.buckets.find(
          (elem) => elem.key === 4
        );
        const canceledData = el.group_by_status.buckets.find(
          (elem) => elem.key === 5
        );
        const total =
          (completedData ? completedData.doc_count : 0) +
          (completedDataPart ? completedDataPart.doc_count : 0) +
          (hasntArrived ? hasntArrived.doc_count : 0) +
          (canceledData ? canceledData.doc_count : 0);
        const fullData = [
          moment(el.key_as_string, 'DD.MM.YY').format('DD.MMM.YYYY'),
          total
        ];
        const fullCompleted =
          completedData && completedDataPart
            ? completedData.doc_count + completedDataPart.doc_count
            : completedData
            ? completedData.doc_count
            : completedDataPart
            ? completedDataPart.doc_count
            : 0;
        const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
        const fullCanceled = canceledData ? canceledData.doc_count : 0;

        if (!completedData) {
          fullData.push(
            `${fullCompleted} (${
              total ? Math.floor((fullCompleted / total) * 100) : 0
            }%)`
          );
          fullData.push(
            `${fullCanceled} (${
              total ? Math.floor((fullCanceled / total) * 100) : 0
            }%)`
          );
          fullData.push(
            `${fullHasntArrived} (${
              total ? Math.floor((fullHasntArrived / total) * 100) : 0
            }%)`
          );
          fullData.push(`0 ${currency}`);
          fullData.push(`0 ${currency}`);
          fullData.push(`0 ${currency}`);
          fullData.push(`0 ${currency}`);
        } else {
          const numList = el.group_by_status.buckets
            .map((status) => {
              if (status.key === 6 || status.key === 7) {
                return status.price.buckets.map((price) => {
                  return [price.doc_count, price.key];
                }, 0);
              }
              return 0;
            })
            .filter((el) => el !== 0)
            .flat(1);
          fullData.push(
            `${fullCompleted} (${
              total ? Math.floor((fullCompleted / total) * 100) : 0
            }%)`
          );
          fullData.push(
            `${fullCanceled} (${
              total ? Math.floor((fullCanceled / total) * 100) : 0
            }%)`
          );
          fullData.push(
            `${fullHasntArrived} (${
              total ? Math.floor((fullHasntArrived / total) * 100) : 0
            }%)`
          );
          fullData.push(
            numList.reduce((acc, num) => acc + num[0] * num[1], 0) +
              ' ' +
              currency
          );
          fullData.push(
            Math.max(...numList.map((el) => el[1])) + ' ' + currency
          );
          fullData.push(
            Math.min(...numList.map((el) => el[1])) + ' ' + currency
          );
          fullData.push(
            (
              numList.reduce((acc, el) => el[1] * el[0] + acc, 0) /
              fullCompleted
            ).toFixed(2) +
              ' ' +
              currency
          );
        }

        return fullData;
      })
    );
  };

  const getPopularServiceStatistic = (statisticData) => {
    setMainLabels([
      t('statistic.serviceName'),
      t('statistic.totalRecords'),
      t('statistic.income'),
      t('statistic.maxPrice'),
      t('statistic.minPrice'),
      t('statistic.averagePrice')
    ]);
    setCsvData(
      statisticData.service.buckets.map((el) => {
        const numList = el.price.buckets.map((num) => {
          const count = num.status.buckets.reduce((acc, status) => {
            return status.key === 6 || status.key === 7
              ? acc + status.doc_count
              : acc;
          }, 0);
          return { key: num.key, count: count };
        });
        const filteredList = numList.filter((item) => item.count !== 0);
        const sum = filteredList.reduce((acc, item) => acc + item.key, 0);
        const maxKey = sum
          ? Math.max(...filteredList.map((item) => item.key))
          : 0;
        const minKey = sum
          ? Math.min(...filteredList.map((item) => item.key))
          : 0;
        const average = sum ? (sum / filteredList.length).toFixed(2) : 0;
        const list = [];
        list.push(
          `${el.key}${
            !!el.serviceDeleted.buckets[0].key ? ` ${t('filters.archive')}` : ''
          }`
        );
        list.push(el.doc_count);
        list.push(sum + ' ' + currency);
        list.push(maxKey + ' ' + currency);
        list.push(minKey + ' ' + currency);
        list.push(average + ' ' + currency);
        return list;
      })
    );
  };

  const getPopularMasterStatistic = (statisticData) => {
    setMainLabels([
      t('statistic.master'),
      t('statistic.totalRecords'),
      t('statistic.income'),
      t('statistic.maxPrice'),
      t('statistic.minPrice'),
      t('statistic.averagePrice')
    ]);
    setCsvData(
      statisticData.masters.buckets.map((el) => {
        const numList = el.price.buckets.map((num) => {
          const count = num.status.buckets.reduce((acc, status) => {
            return status.key === 6 || status.key === 7
              ? acc + status.doc_count
              : acc;
          }, 0);
          return { key: num.key, count: count };
        });
        const filteredList = numList.filter((item) => item.count !== 0);
        const sum = filteredList.reduce((acc, item) => acc + item.key, 0);
        const maxKey = sum
          ? Math.max(...filteredList.map((item) => item.key))
          : 0;
        const minKey = sum
          ? Math.min(...filteredList.map((item) => item.key))
          : 0;
        const average = sum ? (sum / filteredList.length).toFixed(2) : 0;
        const list = [];
        list.push(
          `${el.name.buckets[0].key} ${el.surname.buckets[0].key} (${el.position.buckets[0].key})`
        );
        list.push(el.doc_count);
        list.push(sum + ' ' + currency);
        list.push(maxKey + ' ' + currency);
        list.push(minKey + ' ' + currency);
        list.push(average + ' ' + currency);
        return list;
      })
    );
  };

  const getCompleteMasterStatistic = (statisticData) => {
    setMainLabels([
      t('statistic.master'),
      t('statistic.totalRecords'),
      t('statistic.bookingCompleted'),
      t('statistic.bookingCancel'),
      t('statistic.clientNotCome')
    ]);
    setCsvData(
      statisticData.masters.buckets.map((el) => {
        const list = [];
        list.push(
          `${el.name.buckets[0].key} ${el.surname.buckets[0].key} (${el.position.buckets[0].key})`
        );
        const completedData = el.status.buckets.find((comp) => comp.key === 6);
        const completedDataPart = el.status.buckets.find(
          (comp) => comp.key === 7
        );
        const hasntArrived = el.status.buckets.find((elem) => elem.key === 4);
        const canceledData = el.status.buckets.find((elem) => elem.key === 5);
        const totalSum =
          (completedData ? completedData.doc_count : 0) +
          (hasntArrived ? hasntArrived.doc_count : 0) +
          (canceledData ? canceledData.doc_count : 0) +
          (completedDataPart ? completedDataPart.doc_count : 0);
        list.push(totalSum);
        const fullCompleted =
          completedData && completedDataPart
            ? completedData.doc_count + completedDataPart.doc_count
            : completedData
            ? completedData.doc_count
            : completedDataPart
            ? completedDataPart.doc_count
            : 0;
        const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
        const fullCanceled = canceledData ? canceledData.doc_count : 0;
        list.push(
          `${fullCompleted} (${
            totalSum ? Math.floor((fullCompleted / totalSum) * 100) : 0
          }%)`
        );
        list.push(
          `${fullCanceled} (${
            totalSum ? Math.floor((fullCanceled / totalSum) * 100) : 0
          }%)`
        );
        list.push(
          `${fullHasntArrived} (${
            totalSum ? Math.floor((fullHasntArrived / totalSum) * 100) : 0
          }%)`
        );
        return list;
      })
    );
  };

  const getCompleteServiceStatistic = (statisticData) => {
    setMainLabels([
      t('statistic.serviceName'),
      t('statistic.subcategoryCategory'),
      t('statistic.totalRecords'),
      t('statistic.bookingCompleted'),
      t('statistic.bookingCancel'),
      t('statistic.clientNotCome')
    ]);
    setCsvData(
      statisticData.service.buckets.map((el) => {
        const list = [];
        const completedData = el.status.buckets.find((comp) => comp.key === 6);
        const completedDataPart = el.status.buckets.find(
          (comp) => comp.key === 7
        );
        const hasntArrived = el.status.buckets.find((elem) => elem.key === 4);
        const canceledData = el.status.buckets.find((elem) => elem.key === 5);
        list.push(
          `${el.key}${
            !!el.serviceDeleted.buckets[0].key ? ` ${t('filters.archive')}` : ''
          }`
        );
        list.push([
          `${el.subcategory.buckets[0].key}${
            !!el.subcategory.buckets[0].subcategoryDeleted.buckets[0].key
              ? ` ${t('filters.archive')}`
              : ''
          } (${el.category.buckets[0].key}${
            !!el.category.buckets[0].parentCategoryDeleted.buckets[0].key
              ? ` ${t('filters.archive')}`
              : ''
          })`
        ]);
        const totalSum =
          (completedData ? completedData.doc_count : 0) +
          (hasntArrived ? hasntArrived.doc_count : 0) +
          (canceledData ? canceledData.doc_count : 0) +
          (completedDataPart ? completedDataPart.doc_count : 0);
        list.push(totalSum);
        const fullCompleted =
          completedData && completedDataPart
            ? completedData.doc_count + completedDataPart.doc_count
            : completedData
            ? completedData.doc_count
            : completedDataPart
            ? completedDataPart.doc_count
            : 0;
        const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
        const fullCanceled = canceledData ? canceledData.doc_count : 0;
        list.push(
          `${fullCompleted} (${
            totalSum ? Math.floor((fullCompleted / totalSum) * 100) : 0
          }%)`
        );
        list.push(
          `${fullCanceled} (${
            totalSum ? Math.floor((fullCanceled / totalSum) * 100) : 0
          }%)`
        );
        list.push(
          `${fullHasntArrived} (${
            totalSum ? Math.floor((fullHasntArrived / totalSum) * 100) : 0
          }%)`
        );
        return list;
      })
    );
  };

  const downloadCsv = async () => {
    setLoading(true);
    const statisticData = await getPaginationStat(
      periodToEnd,
      periodToStart,
      master,
      haircutType,
      service
    );
    if (statisticType === 'DAILY_STATISTIC') {
      getDailyStatistic(statisticData);
    } else if (statisticType === 'POPULAR_SERVICE') {
      getPopularServiceStatistic(statisticData);
    } else if (statisticType === 'POPULAR_MASTER') {
      getPopularMasterStatistic(statisticData);
    } else if (statisticType === 'COMPLETE_MASTER') {
      getCompleteMasterStatistic(statisticData);
    } else if (statisticType === 'COMPLETE_SERVICE') {
      getCompleteServiceStatistic(statisticData);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (csvData.length && mainLabels.length) {
      ref.current.link.click();
      setMainLabels([]);
      setCsvData([]);
    }
  }, [csvData, mainLabels]);

  return (
    <>
      <LoaderMePos opacity={50} toggle={loading} />
      <Button
        variant="outline-pos-main"
        onClick={downloadCsv}
        className="d-flex align-items-center"
        disabled={loading}
      >
        <BsBoxArrowUp className="me-2" />
        {t('statistic.export')}
      </Button>
      <CSVLink
        data={[mainLabels, ...csvData]}
        ref={ref}
        filename={fileName}
        className="d-none"
      />
    </>
  );
};

export default ExportCsvMePos;
