const TotalMePageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={75}
    fill="none"
    {...props}
  >
    <path
      fill="#F5F5F5"
      d="M23.375 19.719a3.469 3.469 0 1 1-6.937 0 3.469 3.469 0 0 1 6.937 0ZM19.391 26.85l6.15 4.1 8.579-8.58c.352-.352.89-.44 1.335-.217l8.733 4.503v8.094a2.312 2.312 0 0 1-2.313 2.313h-27.75a2.312 2.312 0 0 1-2.313-2.313v-2.313s7.12-5.892 7.58-5.586ZM14.125 41.688a2.312 2.312 0 1 0 0 4.624h27.75a2.312 2.312 0 1 0 0-4.624h-27.75ZM14.125 50.938a2.312 2.312 0 1 0 0 4.624H28a2.312 2.312 0 1 0 0-4.624H14.125Z"
    />
    <path
      fill="#F5F5F5"
      d="M.25 9.313A9.25 9.25 0 0 1 9.5.063h37a9.25 9.25 0 0 1 9.25 9.25v55.5a9.25 9.25 0 0 1-9.25 9.25h-37a9.25 9.25 0 0 1-9.25-9.25v-55.5ZM46.5 4.686h-37a4.625 4.625 0 0 0-4.625 4.625v55.5A4.625 4.625 0 0 0 9.5 69.439h37a4.625 4.625 0 0 0 4.625-4.626v-55.5A4.625 4.625 0 0 0 46.5 4.689Z"
    />
  </svg>
);

export default TotalMePageIcon;
