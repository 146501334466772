import {
    CategoryInterfaceInner,
    CategoryPickerCategory,
    CategoryPickerSubcategory,
    CategoryPickerSubcategoryInner
} from "../types/interfaces";

class CategoryService {
    public static ServiceCategoryInner = (serviceCategories : CategoryPickerCategory[]) : CategoryInterfaceInner[] => {
         return  serviceCategories.map((categoryInterface : CategoryPickerCategory): CategoryInterfaceInner => {
                return {
                    ...categoryInterface,
                    isSubcategoryPiked: categoryInterface.subcategoryList.some(value => value.isPicked),
                    isHidden: false,
                    subcategoryList : this.ServiceSubcategoryInner(categoryInterface.subcategoryList)
                };
            }
        );
    }

    public static ServiceSubcategoryInner = (serviceSubcategories : CategoryPickerSubcategory[]) : CategoryPickerSubcategoryInner[] => {
        return serviceSubcategories.map((subcategoryInterface : CategoryPickerSubcategory) : CategoryPickerSubcategoryInner => {
            return {
                ...subcategoryInterface,
                isHidden: false

            }
        })
    }
}

export default CategoryService