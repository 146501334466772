import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { HOUR, MINUTE } from '../../../constants';
import { ColorLabel } from '../../elements/general';
import ChartDayTime from '../../charts/ChartDayTime';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import ChartDayTimeCompare from '../../charts/ChartDayTimeCompare';
import { useTranslation } from 'react-i18next';
import useUrlParams from '../../../hooks/useUrlParams';

const ScansDaytime = () => {
  const {
    filters,
    isCompareMode,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    filters: state.filters,
    isCompareMode: state.filters.isCompareMode,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const { isMePage } = useUrlParams();

  const [activeButton, setActiveButton] = useState(HOUR);
  const [labels, setLabels] = useState([]);
  const [total, setTotal] = useState([]);
  const [uniq, setUniq] = useState([]);
  const [total2, setTotal2] = useState([]);
  const [uniq2, setUniq2] = useState([]);

  const { getScansDay } = useRequestLocation();

  const fetchData = async (period) => {
    try {
      await getScansDay({
        startDate: periodStart,
        endDate: periodEnd,
        period
      }).then((data) => {
        setTotal(data.scans);
        setUniq(data.unique);
        if (period === HOUR) {
          const hours = Array.from(
            { length: 24 },
            (_, i) => `${i % 12 === 0 ? 12 : i % 12}${i < 12 ? 'am' : 'pm'}`
          );
          setLabels(hours);
        } else if (period === MINUTE) {
          const times = [];

          for (let i = 0; i < 1440; i++) {
            let hh = Math.floor(i / 60);
            const mm = String(i % 60).padStart(2, '0');
            const period = hh >= 12 ? 'pm' : 'am';
            hh = hh % 12 === 0 ? 12 : hh % 12;
            const timeStr = `${String(hh).padStart(2, '0')}:${mm} ${period}`;
            times.push(timeStr);
          }
          setLabels(times);
        }
      });
      if (isCompareMode) {
        const data = await getScansDay({
          startDate: secondPeriodStart,
          endDate: secondPeriodEnd,
          period
        });
        setTotal2(data.scans);
        setUniq2(data.unique);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchData(activeButton);
    // eslint-disable-next-line
  }, [activeButton, filters, page]);

  const daytimeTitleText = isMePage
    ? t('mePage.daytimeTitle')
    : t('daytime.title');
  const scansTotalText = isMePage ? t('mePage.totalScans') : t('scans.total');
  const scansUniqueText = isMePage
    ? t('mePage.uniqueUsers')
    : t('scans.unique');

  return (
    <Col xs={12}>
      <div className="p-3 p-lg-4 bg-white h-100">
        <Row className="justify-content-between align-items-center gy-3">
          <Col xs={12} lg="auto">
            <div className="fw-bold fs-4 text-capitalize">
              {daytimeTitleText}
            </div>
            <div className="fs-7 text-gray"> {t('daytime.description')}</div>
          </Col>
          <Col xs={12} lg="auto">
            <ButtonGroup className="w-100 w-lg-auto">
              <Button
                variant="outline-lightGray"
                active={activeButton === HOUR}
                onClick={() => setActiveButton(HOUR)}
              >
                {t('scans.hour')}
              </Button>
              <Button
                variant="outline-lightGray"
                active={activeButton === MINUTE}
                onClick={() => setActiveButton(MINUTE)}
              >
                {t('scans.minute')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="my-4">
          <ChartDayTime labels={labels} dataTotal={total} dataUniq={uniq} />
          {isCompareMode && (
            <ChartDayTimeCompare
              labels={labels}
              dataTotal={total2}
              dataUniq={uniq2}
            />
          )}
        </div>
        <Row className="justify-content-center">
          {!isCompareMode ? (
            <>
              <Col xs="auto" className="text-gray d-flex align-items-center">
                <ColorLabel color={isMePage ? '#3A83F6' : '#9b27af'} />
                {scansTotalText} (
                {total
                  .reduce((acc, item) => (acc += item), 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                )
              </Col>
              <Col xs="auto" className="text-gray d-flex align-items-center">
                <ColorLabel color={isMePage ? '#94BDFF' : '#796FEF'} />
                {scansUniqueText} (
                {uniq
                  .reduce((acc, item) => (acc += item), 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                )
              </Col>
            </>
          ) : (
            <>
              <Col
                xs={6}
                md="auto"
                className="text-gray d-flex align-items-center justify-content-end pe-0"
              >
                {t('devices.previous')}:
              </Col>
              <Col
                xs={6}
                md="auto"
                className="text-gray d-flex align-items-center ps-0"
              >
                <ColorLabel color={isMePage ? '#3A83F6' : '#9b27af'} />
                {scansTotalText} (
                {total2
                  .reduce((acc, item) => (acc += item), 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                )
              </Col>
              <Col
                xs={6}
                md="auto"
                className="text-gray d-flex align-items-center justify-content-end pe-0"
              >
                {t('devices.current')}:
              </Col>
              <Col
                xs={6}
                md="auto"
                className="text-gray d-flex align-items-center ps-0"
              >
                <ColorLabel color={isMePage ? '#94BDFF' : '#796FEF'} />
                {scansUniqueText} (
                {total
                  .reduce((acc, item) => (acc += item), 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                )
              </Col>
            </>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default ScansDaytime;
