import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import enTranslation from './locales/en/translation.json'
import uaTranslation from './locales/ua/translation.json'

if (i18next.isInitialized) {
    // Добавляем или обновляем переводы для текущего языка
    i18next.addResourceBundle('en', 'translation', enTranslation, true, true);
    i18next.addResourceBundle('uk', 'translation', uaTranslation, true, true);
} else {
    i18next
        .use(initReactI18next)
        .use(Backend)
        .init({
            resources: {
                en: {translation: enTranslation},
                uk: {translation: uaTranslation},
                // ...add other languages
            },
            fallbackLng: 'en',

        })
}