import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import MeQrPage from '../../pages/MeQrPage';
import i18n from 'i18next';
import moment from 'moment/moment';
import { LANGUAGES_ARRAY } from '../../constants/languages';
import MePosPage from '../../pages/MePosPage';
import MeTicketPage from '../../pages/MeTicketPage';
import '../../constants/momentLocales';
import MePagePage from '../../pages/MePagePage';

const Wrapper = () => {
  useEffect(() => {
    const lang = window.location.pathname.split('/')[1];
    if (LANGUAGES_ARRAY.includes(lang)) {
      i18n.changeLanguage(lang);
      moment.locale(lang)
    } else {
      moment.locale('en')
    }
  }, []);

  return (
    <Routes>
      <Route index exact path="/meqr" element={<MeQrPage />} />
      <Route index exact path="/mepos" element={<MePosPage />} />
      <Route index exact path="/meticket" element={<MeTicketPage />} />
      <Route index exact path="/mepage" element={<MePagePage />} />
    </Routes>
  );
};

export default Wrapper;
