import React, {SetStateAction, useState} from "react";
import {
    CategoryInterfaceInner,
    CategoryPickerCategory,
    CategoryPickerSubcategoryInner
} from "../../../types/interfaces";
import {useTranslation} from "react-i18next";


type SearchCategoryComponentProps = {
    categoryList: CategoryInterfaceInner[]
    onChange: (currentValue: string) => void
    setCategoryList: React.Dispatch<SetStateAction<CategoryPickerCategory[]>>
}

export const SearchCategoryComponent: React.FC<SearchCategoryComponentProps> = (props) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const {t : translate} = useTranslation();

    const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchQuery: string = event.target.value;
        setSearchValue(searchQuery);
        props.onChange(searchQuery);

        if (searchQuery === '') {
            const resetCategories : CategoryInterfaceInner[] = props.categoryList.map((category : CategoryInterfaceInner) : CategoryInterfaceInner => ({
                ...category,
                isHidden: false,
                subcategoryList: category.subcategoryList.map((subcategory : CategoryPickerSubcategoryInner) : CategoryPickerSubcategoryInner => ({
                    ...subcategory,
                    isHidden: false,
                })),
            }));

            props.setCategoryList(resetCategories);
        } else {
            const filteredCategories : CategoryInterfaceInner[] = props.categoryList.map((category : CategoryInterfaceInner) : CategoryInterfaceInner  => {
                const searchWords = searchQuery.split(" ");

                const categoryMatch = searchWords.some((word) =>
                    category.name.toLowerCase().includes(word)
                );

                const filteredSubcategories : CategoryPickerSubcategoryInner[] = category.subcategoryList.map(
                    (subcategory : CategoryPickerSubcategoryInner) : CategoryPickerSubcategoryInner => ({
                        ...subcategory,
                        isHidden: !subcategory.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()),
                    })
                );

                const subcategoryMatch : boolean = filteredSubcategories.some(
                    (subcategory :CategoryPickerSubcategoryInner) => !subcategory.isHidden
                );

                return {
                    ...category,
                    isHidden: !(categoryMatch || subcategoryMatch),
                    subcategoryList: filteredSubcategories,
                };
            });

            props.setCategoryList(filteredCategories);
        }
    };


    return (
        <input type="search" className={"form-control"} placeholder={translate('search')} value={searchValue}
               onChange={onSearchHandler}/>
    )
}