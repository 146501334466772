import { useEffect } from 'react';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import { useState } from 'react';
import styled from 'styled-components';
import useUrlParams from '../../hooks/useUrlParams';

const ChartMap = ({ mapData }) => {
  const [markers, setMarkers] = useState([]);
  const { isMePage } = useUrlParams();

  const regionStyle = {
    initial: {
      fill: '#DDE0E4',
      'fill-opacity': 1,
      stroke: 'none',
      'stroke-width': 0,
      'stroke-opacity': 0
    },
    hover: { fill: 'blue' },
    selected: { fill: '#DEE2E7' },
    selectedHover: { fill: 'purple' }
  };

  const series = {
    regions: [
      {
        scale: ['#AAAAAA', '#444444'],
        normalizeFunction: 'polynomial'
      }
    ]
  };

  const containerStyle = {
    width: '100%',
    height: '420px'
  };

  const markerStyle = {
    initial: {
      strokeWidth: 0.5,
      fill: isMePage ? '#F8A40F' : '#796FEF',
      stroke: isMePage ? '#F8A40F' : '#796FEF',
      fillOpacity: 1
    },
    hover: { fill: '#3E4957' },
    selected: { fill: '#3E4957' },
    selectedHover: { fill: '#3E4957' }
  };

  useEffect(() => {
    setMarkers([]);
    setTimeout(() => setMarkers(mapData), 100);
  }, [mapData]);

  function handleMarkerTipShow(event, label, code) {
    const marker = markers.find((m) => m.name === label.text());
    if (marker && marker.html) {
      label.html(marker.html);
    }
  }

  return (
    <MapWrapper classname="w-100">
      {markers.length ? (
        <VectorMap
          map={worldMill}
          backgroundColor="transparent"
          zoomOnScroll={false}
          containerStyle={containerStyle}
          containerClassName="map"
          regionStyle={regionStyle}
          series={series}
          markers={mapData}
          onMarkerTipShow={handleMarkerTipShow}
          markerStyle={markerStyle}
        />
      ) : (
        <MapWrapper classname="w-100">
          <VectorMap
            map={worldMill}
            backgroundColor="transparent"
            zoomOnScroll={false}
            containerStyle={containerStyle}
            containerClassName="map"
            regionStyle={regionStyle}
            series={series}
          />
        </MapWrapper>
      )}
    </MapWrapper>
  );
};

export default ChartMap;

const MapWrapper = styled.div`
  height: 300px;
  @media (min-width: 992px) {
    height: 500px;
  }
`;
