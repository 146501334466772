function useUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = urlParams.get('url');
  const qrId = urlParams.get('id');
  const id = qrId ? Number(qrId) : null;
  const noUser = urlParams.get('nouser');
  const token = urlParams.get('token');
  const isMePage = window.location.pathname.includes('/mepage');

  return { baseUrl, id, noUser, token, isMePage };
}
export default useUrlParams;
