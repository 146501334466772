import React, {useEffect, useState} from "react";
import styles from "./SubcategoryComponent.module.css"
import listStyle from "../CategoryComponent.module.css"
import {CategoryPickerSubcategory} from "../../../../types/interfaces";

type SubcategoryComponentProps = {
    subcategory: CategoryPickerSubcategory
    isOpen: boolean
    onSubcategoryPick: (currentSubcategory: CategoryPickerSubcategory) => void
}

export const SubcategoryComponent: React.FC<SubcategoryComponentProps> = (props) => {
    const [subcategory, setSubcategory] = useState<CategoryPickerSubcategory>(props.subcategory);

    useEffect(() => {
        setSubcategory(props.subcategory)
    }, [props.subcategory]);
    const pickSubcategoryHandler = () => {
        props.onSubcategoryPick(subcategory);
    }

    return (
        <ul className={`${styles.category} ${props.isOpen ? styles.open : ''} list-unstyled ms-3`}>
            <li className={`${listStyle.customListStyle} ${subcategory.isPicked ? listStyle.pickedBg + ' text-primary' : ''} 
          ps-4 my-2 position-relative`}
                onClick={pickSubcategoryHandler}>
                {props.subcategory.name}
                {subcategory.isPicked &&
                    <i className="bi bi-check-lg text-primary position-absolute end-0 translate-middle-x"></i>}
            </li>
        </ul>
    )
}